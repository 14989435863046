<template>
  <div class="pricing">
    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="addDialog = !addDialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>
    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this pricing:
          <b>{{ delPricingInfo.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelPricingInfo()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add dialog -->
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Pricing Info
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px;">
            <v-card-text>
              <v-select
                v-model="bumperPricingIn.bumperInfoId"
                :items="boostTypeList"
                item-value="id"
                item-text="text"
                label="Boost Type"
              >
              </v-select>
            </v-card-text>
            <v-row>
              <v-text-field
                v-model="bumperPricingIn.name"
                label="Name"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="bumperPricingIn.description"
                label="Description"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="bumperPricingIn.click"
                label="Click"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="bumperPricingIn.impression"
                label="Impression"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="bumperPricingIn.price"
                label="Price"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn text color="primary" @click="doAddInfo()">Submit</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editInfo.name }} Status
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-container style="padding: 30px;">
            <v-row>
              <v-text-field v-model="editInfo.name" label="Name"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editInfo.description"
                label="Description"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editInfo.click"
                label="Click"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editInfo.impression"
                label="Impression"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editInfo.price"
                label="Price"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn text color="primary" @click="doEditInfo()">Submit</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchInfo()"
          v-model="infoSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchInfo()"
          @keyup.enter.native="searchInfo()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="infoList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.description }}</td>
          <td>{{ row.item.click }}</td>
          <td>{{ row.item.impression }}</td>
          <td>{{ row.item.price }}</td>
          <td>{{ row.item.type }}</td>
          <td>{{ row.item.status }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      infoSearch: "",
      infoStatus: "",
      token: "",
      pageCount: 0,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Click Limit", value: "click" },
        { text: "Impression Limit", value: "impression" },
        { text: "Price", value: "price" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      infoList: [],
      bumperPricingIn: {
        bumperInfoId: "",
        name: "",
        description: "",
        click: "",
        impression: "",
        price: "",
        type: "REFRESHER",
      },
      editInfo: {
        name: "",
        description: "",
        click: "",
        impression: "",
        price: "",
      },
      delPricingInfo: {
        id: "",
        name: "",
      },
      boostTypeList: [
        {
          id: "4028810674135e250174135e69650000",
          text: "One Time Push (REFRESHER)",
          value: "REFRESHER",
        },
        {
          id: "402881067413755401741378df820001",
          text: "Stick to Top (BUMPER)",
          value: "BUMPER",
        },
      ],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchInfoList();
  },
  methods: {
    searchInfo() {
      this.fetchInfoList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchInfoList();
    },
    editButtonClick(item) {
      this.editInfo.id = item.id;
      this.editInfo.name = item.name;
      this.editInfo.description = item.description;
      this.editInfo.click = item.click;
      this.editInfo.impression = item.impression;
      this.editInfo.price = item.price;
      this.editDialog = true;
    },
    async doAddInfo() {
      const self = this;
      try {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/bumperSystem/addBumperPricing`,
          this.bumperPricingIn,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.addDialog = false;
            self.fetchInfoList();
            self.$message({
              message: "Pricing Info Added Successfully!",
              type: "success",
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    async doEditInfo() {
      const self = this;

      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/bumperSystem/updBumperPricing`,
          this.editInfo,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchInfoList();
            self.$message({
              message: "Pricing Info Edited Successfully!",
              type: "success",
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delPricingInfo.id = item.id;
      this.delPricingInfo.name = item.name;
      this.deleteDialog = true;
    },
    doDelPricingInfo() {
      const self = this;
      const param = {
        bumperPricingId: self.delPricingInfo.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/bumperSystem/delBumperPricing`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchInfoList();
            self.showAlert(
              "success",
              "Advertising Pricing Plan Deleted Successfully!"
            );
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    fetchInfoList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.infoSearch,
        status: self.infoStatus,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/bumperSystem/bumperPricing`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.infoList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
